import React, { useEffect, useState, useRef } from "react";
// import Papa from "papaparse";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import { grayClasses } from "../Design/classes"; // Adjust if needed

// Optional helper if you want 4-second delay per payment
// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

const PagoMasivo = (props) => {
  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [isChecked, setIsChecked] = useState({});
  const [pagoError, setPagoError] = useState("");
  const [filterText, setFilterText] = useState("");

  const isMounted = useRef(true);

  // 1) CSV Upload
  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setLoading(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if (isMounted.current) {
          setWalletData(results.data);
          setLoading(false);
        }
      },
      error: (err) => {
        console.error("Error parsing CSV:", err);
        setLoading(false);
      },
    });
  };

  // 2) Handle row-level checkbox
  const handleCheck = (event) => {
    const { checked, value, name } = event.target;
    setIsChecked((prev) => ({ ...prev, [name]: checked }));

    if (checked) {
      setCheckedState((prev) => [...prev, value]);
    } else {
      setCheckedState((prev) => {
        const newState = [...prev];
        const idx = newState.indexOf(value);
        if (idx !== -1) {
          newState.splice(idx, 1);
        }
        return newState;
      });
    }
  };

  // 3) "Select All" button
  const selectAllRows = () => {
    // We'll convert each row index to a string
    const allIndexes = walletData.map((_, i) => i.toString());
    setCheckedState(allIndexes);

    // Build isChecked object
    const newIsChecked = {};
    for (let i = 0; i < walletData.length; i++) {
      newIsChecked[`pago${i}`] = true;
    }
    setIsChecked(newIsChecked);
  };

  // 4) Send (create) and remove rows
  const ingresarPagos = async () => {
    setLoading(true);
    setPagoError("");

    const token = document.querySelector('[name=csrf-token]')?.content;
    if (token) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    }

    const successIndices = [];

    // Loop over selected indices
    for (const indexStr of checkedState) {
      const idx = parseInt(indexStr, 10);
      const rowObj = walletData[idx];

      if (!rowObj) {
        console.error(`No row found at index ${idx}`);
        continue;
      }

      try {
        // Build payload from rowObj
        const createData = {
          // Example fields matching your original columns
          // You can rename or remove if not needed
          amount: rowObj.amount,
          creditNumber: rowObj.creditNumber,
          tipoPago: rowObj.tipoPago,
          referenciaTransferencia: rowObj.referenciaTransferencia,
          fechaTransferencia: rowObj.fechaTransferencia,
          bancoReceptor: rowObj.bancoReceptor,
          libretaCobro: rowObj.libretaCobro,
          customerNumber: rowObj.customerNumber,
          nombreCliente: rowObj.nombreCliente,
          nombreDepositante: rowObj.nombreDepositante,
          conciliado: "false",
        };

        // If you want a 4-second delay per payment:
        // await sleep(4000);

        await axios.post("/api/v1/safi/save_pago", createData);
        successIndices.push(idx);
      } catch (error) {
        console.error("Error creating pago:", error);
        setPagoError("Error al crear uno de los pagos.");
      }
    }

    // Remove the successfully created rows
    if (successIndices.length > 0) {
      const successSet = new Set(successIndices);
      const newWalletData = walletData.filter((_, rowIndex) => !successSet.has(rowIndex));
      setWalletData(newWalletData);
    }

    // Clear checkboxes
    setCheckedState([]);
    setIsChecked({});

    setLoading(false);
  };

  // Filter logic
  const includesFilterText = (text) =>
    String(text).toLowerCase().includes(filterText.toLowerCase());

  const getFilteredData = () => {
    return walletData.filter((row) => {
      return (
        includesFilterText(row.usuario) ||
        includesFilterText(row.amount) ||
        includesFilterText(row.nombreCliente) ||
        includesFilterText(row.creditNumber) ||
        includesFilterText(row.tipoPago) ||
        includesFilterText(row.bancoReceptor) ||
        includesFilterText(row.referenciaTransferencia) ||
        includesFilterText(row.libretaCobro) ||
        includesFilterText(row.nombreDepositante)
      );
    });
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex h-screen">
          <div className="m-auto">
            <Bars color="#00BFFF" height={100} width={100} timeout={30000} />
          </div>
        </div>
      ) : (
        <div className="overflow-y-auto">
          {/* UPLOAD CSV */}
          <div className="p-5">
            <label className="flex flex-col sm:flex-row gap-x-2 items-baseline">
              <span className="text-gray-700 mb-2 sm:mb-0">Agregar CSV: </span>
              <input
                type="file"
                accept=".csv,.txt"
                onChange={handleCSVUpload}
                className="mb-2 sm:mb-0"
              />
            </label>
          </div>

          {/* FILTER */}
          <div className="p-5 sm:flex sm:gap-x-2 sm:justify-between">
            <label className="flex gap-x-2 items-baseline">
              <span className="text-gray-700">Buscar: </span>
              <input
                type="text"
                placeholder="Filtrar resultados"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </label>
          </div>

          {/* TABLE */}
          <div className="px-7 py-5 flex flex-wrap -mx-5 mb-2">
            <div className="ml-2 mt-2 w-full mr-2">
              <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                <table className="min-w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      {/* 1) Single row checkbox column */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        {/* Empty header for the row-level checkboxes */}
                      </th>
                      {/* 2) ID Transacción / Usuario */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        ID Transacción / Usuario
                      </th>
                      {/* 3) Número de Referencia */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Número de Referencia
                      </th>
                      {/* 4) Fecha de Transferencia */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Fecha de Transferencia
                      </th>
                      {/* 5) Tipo de Pago */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Tipo de Pago
                      </th>
                      {/* 6) No. de Crédito */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        No. de Crédito
                      </th>
                      {/* 7) Nombre de Cliente */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Nombre de Cliente
                      </th>
                      {/* 8) Depositante */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Depositante
                      </th>
                      {/* 9) Banco Receptor */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Banco Receptor
                      </th>
                      {/* 10) No. Libre de Cobro */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        No. Libre de Cobro
                      </th>
                      {/* 11) Monto Pagado */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Monto Pagado
                      </th>
                      {/* 12) Comprobante */}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
                        Comprobante
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getFilteredData().map((row, i) => {
                      const currentGrayClass = grayClasses[i % grayClasses.length];

                      // Construct shortened ID if row._id is present
                      const shortenedID = row._id
                        ? String(row._id).slice(-5)
                        : "NoID";

                      return (
                        <tr key={i} className={`${currentGrayClass} border-b border-gray-200`}>
                          {/* 1) Row-level checkbox */}
                          <td className="px-6 py-3 text-center">
                            <input
                              type="checkbox"
                              name={`pago${i}`}
                              value={i}
                              onChange={handleCheck}
                              checked={isChecked[`pago${i}`] || false}
                            />
                          </td>

                          {/* 2) ID Transacción / Usuario */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {`PagoID: ${shortenedID} / Usuario: ${
                              row.usuario || "Sin Usuario"
                            }`}
                          </td>

                          {/* 3) Número de Referencia */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.referenciaTransferencia}
                          </td>

                          {/* 4) Fecha de Transferencia */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.fechaTransferencia || ""}
                          </td>

                          {/* 5) Tipo de Pago */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.tipoPago}
                          </td>

                          {/* 6) No. de Crédito */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.creditNumber}
                          </td>

                          {/* 7) Nombre de Cliente */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.nombreCliente}
                          </td>

                          {/* 8) Depositante */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.nombreDepositante}
                          </td>

                          {/* 9) Banco Receptor */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.bancoReceptor}
                          </td>

                          {/* 10) No. Libre de Cobro */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.libretaCobro}
                          </td>

                          {/* 11) Monto Pagado */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {row.amount
                              ? new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                }).format(row.amount)
                              : "$0.00"}
                          </td>

                          {/* 12) Comprobante */}
                          <td className="px-6 py-3 text-sm text-gray-500 text-center">
                            {/* If CSV has attachments as comma-separated or single URL */}
                            {row.attachments &&
                              row.attachments
                                .split(",")
                                .map((link, idxAttach) => (
                                  <div key={idxAttach}>
                                    <a
                                      href={link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="underline text-blue-600 hover:text-blue-800"
                                    >
                                      Anexo
                                    </a>
                                  </div>
                                ))}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {pagoError && (
            <div className="text-red-700 font-semibold ml-5 mb-4">{pagoError}</div>
          )}

          {/* ACTION BUTTONS */}
          <div className="mx-6 mb-6 text-right">
            {/* "Select All" button (Green) */}
            <button
              onClick={selectAllRows}
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-6 rounded mr-4"
            >
              Seleccionar Todos
            </button>

            {/* "Ingresar Pagos" button (Blue) */}
            <button
              onClick={ingresarPagos}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded"
            >
              Ingresar Pagos
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default PagoMasivo;
